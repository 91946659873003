<template>
    <div class="rate" :class="{'disabled':disabled}">
        <span v-if="showText" class="text">{{curScore||score}}分</span>
        <div class="star-wrap">
            <i v-for="(i, index) in 5" :key="index" :style="{'width':starWidth+'px','height':starWidth+'px'}" @mouseenter="disabled ? '' : curScore=i" @mouseleave="disabled ? '' : curScore=''" @click="disabled ? '' : setScore(i)" :class="getClass(i)">
                <i v-if="disabled&&i==Math.floor(score)" class="icon-star" :style="{'width':starWidth+'px','height':starWidth+'px'}"></i>
            </i>
        </div>
    </div>
</template>
<script>
export default {
    name: 'MyRate',
    props: {
        // 分数，默认0，保留一位小数
        score: {
            type: Number,
            default: 0
        },
        // 是否只读，默认false，鼠标点击可以打分
        disabled: {
            type: Boolean,
            default: false
        },
        // 是否显示分数，默认false
        showText: {
            type: Boolean,
            default: false
        },
        // 星星的大小
        starWidth: {
            type: String,
            default: '20'
        }
    },
    data () {
        return {
            curScore: '',
        }
    },
    created: function () {
        this.getDecimal();
    },
    methods: {
        getClass (i) {
            if (this.curScore === '') {
                return i <= this.score ? 'icon-star' : 'icon-star-o';
            } else {
                return i <= this.curScore ? 'icon-star' : 'icon-star-o';
            }
        },
        getDecimal () {
            this.width = Number(this.score * 100 - Math.floor(this.score) * 100) + '%';
        },
        setScore (i) {
            this.$emit('update:score', i);
        }
    }
}
</script>
<style lang="scss" scoped>
.rate{
    display: inline-block;
    vertical-align: middle;
    .text{
        font-size:18px;
        color: #ff7f2c;
        font-weight: bold;
    }
    .star-wrap{
        display: inline-block;
        .icon-star-o{
            position: relative;
            width:20px;
            height: 20px;
            display: inline-block;
            margin-right: 2px;
            background: url(~@/assets/images/icon_star1.png) no-repeat;   // 这边是没有选中星星的图片
            background-size: 100%;
            .icon-star{
                position: absolute;
                left:0;
                top:0;
            }
        }
        .icon-star{
            width:20px;
            height: 20px;
            display: inline-block;
            margin-right: 2px;
            background: url(~@/assets/images/icon_star6.png) no-repeat;   // 这边是选中之后星星的图片
            background-size: 100%;
        }
        i:last-child{
            margin-right: 0px;
        }
    }
}
 </style>