<template>
    <div class="wrap">
        <!-- 轮播图 -->
        <!-- <div class="" v-show="sildImgList.length>0">
            <b-carousel id="carousel-1" v-model="slide" :interval="4000" indicators background="#ababab" img-width="1024" img-height="300" style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
                <b-carousel-slide v-for="(item,keys) in sildImgList" :key="keys" :img-src="item.img_url" @click.native="linkTo('1',item)"></b-carousel-slide>
            </b-carousel>
        </div> -->
        <!-- 轮播图 -->
        <div class="pc-ban-box" v-show="sildImgList.length>0">
            <b-carousel id="carousel-1" ref="bcarousel" v-model="slide" :interval="4000" indicators background="#ababab" img-width="1024" img-height="300" style="text-shadow: 1px 1px 2px #333;" @sliding-start="onSlideStart" @sliding-end="onSlideEnd">
                <b-carousel-slide v-for="(item,keys) in sildImgList" :key="keys" :img-src="item.img_url" @click.native="linkTo('1',item)"></b-carousel-slide>
            </b-carousel>
        </div>
        <!-- wap-轮播图 -->
        <div class="wap-ban-box" v-show="sildImgListwap.length>0">
            <van-swipe id="swipe" class="my-wap-swipe" :autoplay="4000" indicator-color="white">
                <van-swipe-item v-for="(item,keys) in sildImgListwap" :key="keys" @click.native="linkTo('1',item)"><img :src="item.img_url" alt=""></van-swipe-item>
            </van-swipe>
        </div>
        <!-- pc布局【商品展示】 -->
        <div class="pc-product-wrap" v-show="shopList.length>0">
            <b-container class="commodity-group">
                <b-row style="margin:0">
                    <b-col style="max-width:25%" v-for="(item,index) in shopList" :key="index" v-show="index<4">
                    <!--  @mouseenter="changeImageSrc(index, item)" @mouseleave="changeImageSrc(index, '')" -->
                        <div class="commodity-item" @click="getShopInfo(item)">
                            <div class="commodity-item-img">
                                <img :src="item.goods_img" class="left-btn-img" alt=""/>
                            </div>
                            <div class="commodity-item-tit">
                                {{languageName=='cn'?item.cn_name:''}}
                                {{languageName=='en'?item.en_name:''}}
                                {{languageName=='it'?item.it_name:''}}
                            </div>
                            <div class="commodity-item-info">
                                {{languageName=='cn'?item.cn_profile:''}}
                                {{languageName=='en'?item.en_profile:''}}
                                {{languageName=='it'?item.it_profile:''}}  
                            </div>
                            <div class="commodity-item-money">{{$t('message.m')}}{{item.moneyOne}}</div>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        <!-- wap布局【商品展示】 -->
        <div class="wap-product-wrap" v-show="shopList.length>0">
            <b-container class="wap-commodity-group">
                <el-row>
                    <el-col :span="12" v-for="(item,index) in shopList" :key="index" v-show="index<4">
                        <div class="wap-commodity-item" @click="getShopInfo(item)">
                            <div class="wap-commodity-item-img">
                                <img :src="item.goods_img" class="left-btn-img" alt=""/>
                            </div>
                            <div class="wap-commodity-item-tit" v-show="languageName=='cn'">{{item.cn_name}}</div>
                            <div class="wap-commodity-item-tit" v-show="languageName=='en'">{{item.en_name}}</div>
                            <div class="wap-commodity-item-tit" v-show="languageName=='it'">{{item.it_name}}</div>
                            <div class="wap-commodity-item-info">
                                {{languageName=='cn'?item.cn_profile:''}}
                                {{languageName=='en'?item.en_profile:''}}
                                {{languageName=='it'?item.it_profile:''}}  
                            </div>
                            <div class="wap-commodity-item-money">
                                {{$t('message.m')}}{{item.moneyOne}}
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </b-container>
        </div>
        <!-- pc布局【图文展示】 -->
        <div class="pc-product-showinfo pc-show">
            <b-container class="pc-product-info">
                <el-row style="margin:0">
                    <el-col :span="12">
                        <div class="productBox leftbig" v-show="sildImgList2&&sildImgList2.img_url" :style="{'background':'url('+sildImgList2.img_url+')'}">
                            <div class="lias">
                                <div class="productBox-tit">
                                    {{languageName=='cn'?sildImgList2.cn_title:''}}
                                    {{languageName=='en'?sildImgList2.en_title:''}}
                                    {{languageName=='it'?sildImgList2.it_title:''}}     
                                </div>
                                <div class="productBox-more" @click="linkTo('2')">{{$t('message.nowshop')}}<span class="el-icon-arrow-right"></span></div>
                            </div>
                        </div>
                    </el-col>
                    <el-col :span="12" >
                        <div class="productRigth righttop" v-show="sildImgList3&&sildImgList3.img_url" :style="{'background':'url('+sildImgList3.img_url+')'}">
                            <div class="lias">
                                <div class="productBox-title">
                                    {{languageName=='cn'?sildImgList3.cn_title:''}}
                                    {{languageName=='en'?sildImgList3.en_title:''}}
                                    {{languageName=='it'?sildImgList3.it_title:''}}    
                                </div>
                                <div class="productBox-morele" @click="linkTo('3')">{{$t('message.moreinfo')}}<span class="el-icon-arrow-right"></span></div>
                            </div>
                        </div> 
                        <div class="productRigth rightbtm" v-show="sildImgList4&&sildImgList4.img_url" :style="{'background':'url('+sildImgList4.img_url+')'}">
                            <div class="lias">
                                <div class="productBox-title">
                                    {{languageName=='cn'?sildImgList4.cn_title:''}}
                                    {{languageName=='en'?sildImgList4.en_title:''}}
                                    {{languageName=='it'?sildImgList4.it_title:''}}  
                                </div>
                                <div class="productBox-morele" @click="linkTo('4')">{{$t('message.findshop')}}<span class="el-icon-arrow-right"></span></div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </b-container>
        </div>
        <!-- wap布局【图文展示】 -->
        <div class="wap-product-showinfo">
            <b-container class="wap-product-info">
                <b-row  v-show="sildImgList2&&sildImgList2.img_url">
                    <div class="wap-productBox"  :style="{'background':'url('+sildImgList2.img_url+')'}">
                        <div class="lias">
                            <div class="wap-productBox-tit">
                                {{languageName=='cn'?sildImgList2.cn_title:''}}
                                {{languageName=='en'?sildImgList2.en_title:''}}
                                {{languageName=='it'?sildImgList2.it_title:''}}
                            </div>
                            <!-- <div class="wap-productBox-txt">{{$t('message.homepro2')}}</div> -->
                            <div class="wap-productBox-more" @click="linkTo('2')">{{$t('message.nowshop')}}<span class="el-icon-arrow-right"></span></div>
                        </div>
                    </div>
                </b-row>
                <b-row  v-show="sildImgList3&&sildImgList3.img_url">
                    <div class="wap-productBox2 rowtop"  :style="{'background':'url('+sildImgList3.img_url+')'}">
                        <div class="lias">
                            <div class="wap-productBox-title">
                                {{languageName=='cn'?sildImgList3.cn_title:''}}
                                {{languageName=='en'?sildImgList3.en_title:''}}
                                {{languageName=='it'?sildImgList3.it_title:''}}
                            </div>
                            <div class="wap-productBox-morele" @click="linkTo('3')">{{$t('message.moreinfo')}}<span class="el-icon-arrow-right"></span></div>
                        </div>
                    </div>
                </b-row>
                <b-row  v-show="sildImgList4&&sildImgList4.img_url">
                    <div class="wap-productBox2 rowbtm"  :style="{'background':'url('+sildImgList4.img_url+')'}">
                        <div class="lias">
                            <div class="wap-productBox-title">
                                {{languageName=='cn'?sildImgList4.cn_title:''}}
                                {{languageName=='en'?sildImgList4.en_title:''}}
                                {{languageName=='it'?sildImgList4.it_title:''}}    
                            </div>
                            <div class="wap-productBox-morele"  @click="linkTo('4')">{{$t('message.findshop')}}<span class="el-icon-arrow-right"></span></div>
                        </div>
                    </div>
                </b-row>
                <!-- <b-row>
                    <div class="wap-productBox" v-show="sildImgList2&&sildImgList2.img_url" @click="linkTo('2')">
                        <div class="rig_img"><img :src="sildImgList2.img_url" alt=""/></div>
                        <div class="lias">
                            <div class="wap-productBox-tit">{{sildImgList2.title}}</div>
                            <div class="wap-productBox-txt">{{sildImgList2.sketch}}</div>
                            <div class="wap-productBox-more">{{$t('message.nowshop')}}<span class="el-icon-arrow-right"></span></div>
                        </div>
                    </div>
                </b-row>
                <b-row>
                    <div class="wap-productBox2 " v-show="sildImgList3&&sildImgList3.img_url" @click="linkTo('3')">
                        <div class="rig_img"><img :src="sildImgList3.img_url" alt=""/></div>
                        <div class="lias">
                            <div class="wap-productBox-title">{{sildImgList3.title}}</div>
                            <div class="wap-productBox-morele">{{$t('message.moreinfo')}}<span class="el-icon-arrow-right"></span></div>
                        </div>
                    </div>
                </b-row>
                <b-row>
                    <div class="wap-productBox2 " v-show="sildImgList4&&sildImgList4.img_url" @click="linkTo('4')">
                        <div class="rig_img"><img :src="sildImgList4.img_url" alt=""/></div>
                        <div class="lias">
                            <div class="wap-productBox-title">{{sildImgList4.title}}</div>
                            <div class="wap-productBox-morele">{{$t('message.findshop')}}<span class="el-icon-arrow-right"></span></div>
                        </div>
                    </div>
                </b-row> -->
            </b-container>
        </div> 
        <div class="pc-product-showinfo">
            <b-container class="pc-product-content">
                <div class="title">
                    {{articleObj.my_title}}
                </div>
                <div class="" v-show="ariflag">
                    <div class="content" v-html="articleObj&&articleObj.my_content"></div>
                </div>
                <div class="content" v-show="!ariflag">
                    {{articleObj.my_description}}
                </div>
                <div class="more" @click="ariflag=!ariflag">
                    {{ariflag?$t('message.hide'):$t('message.seeall')}}<span :class="ariflag?'el-icon-arrow-up':'el-icon-arrow-down'"></span>
                </div>
            </b-container>
        </div>
        <div class="mg"></div>
        <div class="product-comment">
            <!-- pc布局【综合评价】 -->
            <b-container class="product-content">
                <div class="commenttitle">
                    <span class="pc-title">{{$t('message.allcomment')}}</span>
                    <my-rate :starWidth="'28'" :score="rateNum" :disabled="true"/>
                </div>
                <div class="commentinfo">
                    {{languageName=='cn'?`综合评分${avgunmber}/5，共${cardList.length}条评论`:''}}
                    {{languageName=='en'?`Overall rating ${avgunmber}/5 with ${cardList.length} comment`:''}}
                    {{languageName=='it'?`Voto complessivo ${avgunmber}/5 con ${cardList.length} commento`:''}}
                </div>
                <div class="more">{{$t('message.usstart')}}</div>
            </b-container>
            <b-container class="pc-waterfall-wrap" v-show="cardList.length>0">
                <waterfall :col="col" :data="cardList">
                    <div class="cell-item" v-for="(item, index) in cardList" :key="index">
                        <div class="item-body">
                            <div class="item-footer">
                                <div class="footer-left">
                                    <div class="name">{{ item.user_name?item.user_name:item.nickname }}</div>
                                </div>
                                <div class="like">
                                    <div class="like-total">{{ item.create_time }}</div>
                                </div>
                            </div>
                            <div class="item-start">
                                <my-rate :score="item.score" :disabled="true"/>
                            </div>
                            <div class="item-desc">{{ item.content }}</div>
                        </div>
                    </div>
                </waterfall>   
            </b-container> 
            <!--  @loadmore="loadmore" @scroll="scroll" -->
            <!-- wap布局【综合评价】 -->
            <b-container class="wap-waterfall-wrap" v-show="cardList.length>0">
                <div class="cell-item" v-for="(item, index) in cardList" :key="index">
                    <div class="item-body">
                        <div class="item-footer">
                            <div class="footer-left">
                                <div class="name">{{ item.user_name?item.user_name:item.nickname }}</div>
                            </div>
                            <div class="like">
                                <div class="like-total">{{ item.create_time }}</div>
                            </div>
                        </div>
                        <div class="item-start">
                            <my-rate :score="item.score" :disabled="true"/>
                        </div>
                        <div class="item-desc">{{ item.content }}</div>
                    </div>
                </div>
            </b-container>
        </div>
    </div>
</template>
<script>
import ElementUI from 'element-ui';
import myRate from '@/components/commons/rates.vue';
import {yooz_lang,getLocalItem} from '@/untils/common';
export default {
    name:'index',
    components:{
        myRate
    },
    data(){
        return{
            windowWidth: document.documentElement.clientWidth,  //实时屏幕宽度
            languageName:'',
            rateNum:1,
            avgunmber:'',
            // 轮播
            slide: 0,
            sliding: null,
            sildImgList:[],
            sildImgListwap:[],
            sildImgList2:{},
            sildImgList3:{},
            sildImgList4:{},
            shopList:[],
            col: 5,
            cardList: [],
            articleObj:{},
            ariflag:false,
            userPople:''//userPople:'company' 企业用户，数量10+
        }
    },
    watch:{	 
        sildImgList(val){
            var _this = this;
            // if(_this.windowWidth>=770){
            //     setTimeout(() => {
            //         _this.$refs.bcarousel.resize();
            //     }, 500);
            // }
        }
    },
    methods: {
        // 轮播图片跳转
        linkTo (type,item) {
            if(type==1){
                if(item&&item.link){
                    window.location.href = item.link;
                }
            }else if(type==2){
                window.location.href = this.sildImgList2.link;
            }else if(type==3){
                window.location.href = this.sildImgList3.link;
            }else if(type==4){
                window.location.href = this.sildImgList4.link;
            }
        },
        // 去查看商品详情
        getShopInfo(item){
            if(item){
                this.$router.push({path:'/info',query:{shopid:item.id}});
            }
        },
        onSlideStart(slide) {
            this.sliding = true
        },
        onSlideEnd(slide) {
            this.sliding = false
        },
        // changeImageSrc (key, item) {
        //     if(item){
        //         item.images = this.productList[key].imageshover;
        //     }else{
        //         this.productList[key].images = this.productList[key].imagesed;
        //     }
        // },
        // 轮播banner图
        getBannerImgList(){
            this.$http.api_datalist_banner({type:'1'}).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.sildImgList = res.data;
                        }
                    }
                }
            });
            this.$http.api_datalist_banner({type:'5'}).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.sildImgListwap = res.data;
                        }
                    }
                }
            });
        },
        getBannerList2(type){
            this.$http.api_datalist_banner({type:type}).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            if(type==2){
                                this.sildImgList2 = res.data;
                            }else if(type==3){
                                this.sildImgList3 = res.data;
                            }else if(type==4){
                                this.sildImgList4 = res.data;
                            }
                        }
                    }
                }
            });
        },
        // 文章详情
        getArticleInfo(){
            this.$http.api_article_info({
                id:'47'
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.articleObj = res.data;
                            if(this.languageName=='cn'){
                                this.articleObj.my_title = res.data.cn_title;
                                this.articleObj.my_description = res.data.cn_description;
                                this.articleObj.my_content = res.data.cn_content;
                            }else if(this.languageName=='en'){
                                this.articleObj.my_title = res.data.en_title;
                                this.articleObj.my_description = res.data.en_description;
                                this.articleObj.my_content = res.data.en_content;
                            }else if(this.languageName=='it'){
                                this.articleObj.my_title = res.data.it_title;
                                this.articleObj.my_description = res.data.it_description;
                                this.articleObj.my_content = res.data.it_content;
                            }
                        }
                    }
                }
            });
        },
        // 商品推荐
        getShopHomeList(){
            this.$http.api_goods_shoplist({
                typeid:'',//typeid（顶级类型）、
                typetwoid:'',//（二级类型）、
                ishome:1,//（是否首页推荐）、
                page:1,//（页数）、
                size:0,//（每页条数默认10条值为0无分页）
                iscompany:this.userPople=='company'?1:0,//（是否为企业身份 1是0否）
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            res.data.map((v,k)=>{
                                if(this.languageName=='cn'){
                                    if(v.cn_price){
                                        if(v.discounts!=0){
                                            v.money = v.cn_price*((v.discounts)/100);//100-
                                            v.moneyOne = v.money.toFixed(2);
                                            v.shopCal = v.cn_price*((v.discounts)/100)*v.goods_no;//100-
                                        }else{
                                            v.moneyOne = v.cn_price;
                                            v.shopCal = v.cn_price*v.goods_no;
                                        }
                                    }else{
                                        v.cn_price = 0;
                                        v.moneyOne = v.cn_price;
                                        v.shopCal = v.cn_price*v.goods_no;
                                    }
                                }else if(this.languageName=='en'){
                                    if(v.en_price){
                                        if(v.discounts!=0){
                                            v.money = v.en_price*((v.discounts)/100);//100-
                                            v.moneyOne = v.money.toFixed(2);
                                            v.shopCal = v.en_price*((v.discounts)/100)*v.goods_no;//100-
                                        }else{
                                            v.moneyOne = v.en_price;
                                            v.shopCal = v.en_price*v.goods_no;
                                        }
                                    }else{
                                        v.en_price = 0;
                                        v.moneyOne = v.en_price;
                                        v.shopCal = v.en_price*v.goods_no;
                                    }
                                }else if(this.languageName=='it'){
                                    if(v.it_price){
                                        if(v.discounts!=0){
                                            v.money = v.it_price*((v.discounts)/100);//100-
                                            v.moneyOne = v.money.toFixed(2);
                                            v.shopCal = v.it_price*((v.discounts)/100)*v.goods_no;//100-
                                        }else{
                                            v.moneyOne = v.it_price;
                                            v.shopCal = v.it_price*v.goods_no;
                                        }
                                    }else{
                                        v.it_price = 0;
                                        v.moneyOne = v.it_price;
                                        v.shopCal = v.it_price*v.goods_no;
                                    }
                                }
                            });
                            this.shopList = res.data;
                        }
                    }
                }
            });
        },
        // 首页评价
        getCommentHomeList(){
            this.$http.api_order_getlistforhome({
                size:100,
                avg:"1",
            }).then((res)=>{
                if(res){
                    if(res.code==1){
                        if(res.data){
                            this.rateNum = parseInt(res.data.age)
                            this.avgunmber = res.data.age;
                            this.cardList = res.data.list;
                        }
                    }
                }
            });
        }
    },
    mounted() {
        var that = this;
        window.onresize = () => {
            return (() => {
                window.fullWidth = document.documentElement.clientWidth;
                that.windowWidth = window.fullWidth; //获取屏幕宽度
            })()
        };
    },
    created(){
        let login_user_info = this.$store.state.data.loginNameStatus.family_name?this.$store.state.data.loginNameStatus:(getLocalItem('login_user_info')?getLocalItem('login_user_info'):null);
        if(login_user_info){
            // 再次判断是否是企业用户(是否企业身份 1是 0否)
            if(login_user_info.is_company==1){
                this.userPople='company';
            }
        }
        this.languageName = localStorage.lang?localStorage.lang:yooz_lang;
        this.getBannerImgList(); // 轮播banner图
        this.getShopHomeList(); // 商品推荐
        this.getCommentHomeList();// 首页评价
        this.getArticleInfo();//文章详情
        let arr = [2,3,4];
        arr.map((v)=>{
            this.getBannerList2(v);
        });
    }
}
</script>
<style lang="scss" scoped>
/deep/.carousel-item{
    max-height: 500px;
    img{
        max-height: 500px;
    }
}
// .el-icon-star-off:before{
//     content: '';
//     width: 20px;
//     height: 20px;
//     display: inline-block;
//     background: url(~@/assets/images/icon_star6.png) no-repeat;   // 这边是没有选中星星的图片
//     background-size: 100%;
// }
// .el-icon-star-on:before{
//     content: '';
//     width: 20px;
//     height: 20px;
//     display: inline-block;
//     background: url(~@/assets/images/icon_star1.png) no-repeat;   // 这边是选中星星的图片
//     background-size: 100%;
// }
.modal-wrap{
    position: fixed;
    left:0;
    top:0;
    width:100%;
    height:100%;
    z-index: 1000;
    background: rgba(0, 0, 0, 0.4);
    .modal-inner{
        position :absolute;
        left : 50%;
        top : 50%;
        z-index: 999;
        transform: translate(-50%, -50%);
        width:500px;
        height:300px;
        border-radius:10px;
        padding:0 30px;
        background: #fff;
        .login-group-tit{
            padding:30px 10px 10px;
            font-size:20px;
        }
        .login-group-txts{
            padding:20px 10px 20px;
            .txt{
                color: #616166;
            }
        }
        .login-group-btn{
            cursor: pointer;
            background: #222;
            border: 1px solid #222;
            color: #fff;
            text-align: center;
            border-radius:4px;
            padding:10px 0;
            margin: 0 30px;
            margin-bottom: 10px;
            
        }
        .login-group-btns{
            cursor: pointer;
            padding:10px 0;
            text-align: center;
            border-radius:4px;
            margin: 0 30px;
            border: 1px solid #222;
            
        }
    }
}

.wrap{
    width:100%;
    padding-bottom: 50px;
    .pc-ban-box{
        height:500px;
        z-index: 1;
        /deep/.carousel{
            height: 100%;
            .carousel-inner{
                height: 100%;
                .carousel-item{
                    height: 100%;
                    img{
                        height: 100%;
                    }
                }
            }
        }
    }
    .wap-ban-box{
        z-index: 1;
        height:200px;
        .my-wap-swipe{
            width: 100%;
            height:200px;
            .van-swipe-item {
                width: 100%;
                height:200px;
                img{
                    width: 100%;
                    height:200px;
                }
            }
            /deep/.van-swipe__indicator{
                width:30px;
                height: 3px;
                border-radius: 4px;
                margin: 0 5px;
                opacity: 0.5;
            }
            /deep/.van-swipe__indicator--active{
                background: #17A69F !important;
            }
        }
        /deep/.carousel{
            height: 100%;
            .carousel-inner{
                height: 100%;
                .carousel-item{
                    height: 100%;
                    img{
                        height: 100%;
                    }
                }
            }
        }
        /deep/.carousel{
            height: 100%;
        }
        /deep/.carousel.pointer-event{
            height: 100%;
            .carousel-inner{
                height: 100%;
                .carousel-item{
                    height: 100%;
                    img{
                        height: 100%;
                    }
                }
            }
        }
    }
    .pc-product-wrap{
        padding-top: 80px;
        background: #f4f7fe;
        .commodity-group{
            .row{
                .col{
                    .commodity-item{
                        background: #fff;
                        border-radius:10px;
                        padding:10px;
                        .commodity-item-img{
                            cursor: pointer;
                            max-height:230px;
                            .left-btn-img{
                                width: 100%;
                                max-height:230px;
                            }
                        }
                        .commodity-item-tit{
                            
                            line-height: 30px;
                        }
                        .commodity-item-info{
                            
                            color: #9d9da5;
                            // padding:10px 0 14px;
                            height: 40px;
                            overflow:hidden;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            -o-text-overflow:ellipsis;
                            // line-height: 30px;
                        }
                        // .commodity-item-money{
                        // }
                    }
                }
            }
            
            
        }
    }
    .pc-product-showinfo{
        background: #f4f7fe;
        .pc-product-info{
            padding:20px 10px;
            .productBox{
                cursor: pointer;
                padding: 50px 0;
                margin:10px 10px 20px;
                .lias{
                    text-decoration: none;
                    .productBox-tit{
                        color: #fff;
                        text-align: center;
                        font-size:24px;
                        padding:10px 0 60px;
                    }
                    .productBox-txt{
                        color: #fff;
                        text-align: center;
                        padding:10px 0 60px;
                    }
                    .productBox-more{
                        color: #1f73b7;
                        text-align: center;
                    }
                }
            }
            .productRigth{
                padding: 50px 0;
                margin:10px 10px 20px;
                border-radius: 15px;
                cursor: pointer;
                .lias{
                    text-decoration: none;
                    cursor: pointer;
                    .productBox-title{
                        text-align: left;
                        font-weight: bold;
                        font-size:24px;
                        padding-top:20px;
                        padding-left:40px;
                        padding-right:0px;
                        color: #2e2e2e;
                    }
                    .productBox-morele{
                        color: #1f73b7;
                        font-weight: bold;
                        text-align: left;
                        padding-top:40px;
                        padding-left:40px;
                    }
                }
                
            }
            .leftbig{
                height:580px;
                // background: url(~@/assets/images/show_cp.png) no-repeat left top;
                background-size: 100% 100% !important;
            }
            .righttop{
                height:280px;
                // background: url(~@/assets/images/link_moreinfo.png) no-repeat left top;
                background-size: 100% 100% !important;
            }
            .rightbtm{
                height:280px;
                // background: url(~@/assets/images/link_map.png) no-repeat left top;
                background-size: 100% 100% !important;
            }
        }
        .pc-product-content{
            background: #fff;
            .title{
                font-size:20px;
                padding:20px 0;
            }
            .content{
                
                line-height: 30px;
            }
            .more{
                cursor: pointer;
                color:#1f73b7;
                
                line-height: 60px;
                .lias{
                    display: block;
                    color:#1f73b7;
                    
                    text-decoration: none;
                    .el-icon-arrow-down{
                        margin-left: 10px;
                        font-weight: bold;
                        color:#1f73b7;
                    }
                }
                
            }
        }
    }
    .mg{
        height: 100px;
        background: #f4f7fe;
    }
    .product-comment{
        background: #fff;
        .product-content{
            padding:40px 20px 0;
            .commenttitle{
                display: flex;
                -webkit-box-orient: horizontal;
                align-items: center;
                justify-content: center;
                
                .pc-title{
                    font-size:22px;
                    margin-right: 10px;
                }
                img{
                    display: inline-block;
                    width: 30px;
                }
            }
            .commentinfo{
                text-align: center;
                line-height: 30px;
                color: #9d9da5;
            }
            .more{
                line-height: 30px;
                
                color: #9d9da5;
            }
        }
    }
    
    .pc-waterfall-wrap{
        
        .vue-waterfall{
            height:400px;
        }
        .cell-item{
            .item-body {
                margin:10px 5px;
                padding:10px 0;
                background: #f8f9fa;
                .item-desc {
                    text-align: left;
                    font-family: Roboto;
                    font-style: normal;
                    font-weight: normal;
                    
                    line-height: 26px;
                    padding:10px;
                    color: #2e2e2e;
                }
                .item-start{
                    display: flex;
                    padding:4px 10px 0;
                    
                    img{
                        width:20px;
                        margin-right: 2px;
                    }
                }
                .item-footer {
                    display: flex;
                    justify-content: space-between;
                    padding:0 10px;
                    .footer-left {
                        display: flex;
                        align-items: center;
                        font-family: SF Pro Display;
                        font-style: normal;
                        font-weight: normal;
                        .name {
                            font-size:16px;
                        }
                        
                    }
                    .like {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-family: SF Pro Display;
                        font-style: normal;
                        font-weight: normal;
                        
                        line-height: 14px;
                        color: rgba(0, 0, 0, 0.4);
                    }
                }
            }
            .cell-item {
                width: 96%;
                margin-bottom: 6px;
                background: #f8f9fa;
                border: 2px solid #f0f0f0;
                border-radius: 6px;
                margin:0 2%;
                overflow: hidden;
                box-sizing: border-box;
            }
        }
    }
}

@media screen and (min-width: 1200px) {
    .wrap{
        .wap-ban-box{display: none};
        .wap-product-wrap{display: none;}
        .wap-product-showinfo{display: none;}
        .wap-waterfall-wrap{display: none;}
        .pc-product-showinfo{
            .pc-product-content{
                padding: 40px;
            }
        }
    }
}
@media screen and(min-width: 960px) and (max-width: 1199px) {
    .wrap{
        .wap-ban-box{display: none};
        .wap-product-wrap{display: none;}
        .wap-product-showinfo{display: none;}
        .wap-waterfall-wrap{display: none;}
        
        .pc-product-showinfo{
            .pc-product-info{
                padding:20px 10px;
                .productBox{
                    padding: 30px 0;
                    .lias{
                        text-decoration: none;
                        .productBox-tit{
                            font-size:20px;
                        }
                        
                    }
                }
                .productRigth{
                    padding: 10px 0;
                    margin:10px 10px 20px;
                    .lias{
                        text-decoration: none;
                        .productBox-title{
                            text-align: left;
                            font-weight: bold;
                            font-size:20px;
                            padding-top:20px;
                            padding-left:20px;
                            padding-right:20px;
                            color: #2e2e2e;
                        }
                        .productBox-morele{
                            color: #1f73b7;
                            font-weight: bold;
                            text-align: left;
                            
                            padding-top:20px;
                            padding-left:20px;
                        }
                    }
                }
                .leftbig{
                    height:380px;
                    // background: url(~@/assets/images/show_cp.png) no-repeat left top;
                    background-size: 100% 100% !important;
                }
                .righttop{
                    height:180px;
                    // background: url(~@/assets/images/link_moreinfo.png) no-repeat left top;
                    background-size: 100% 100% !important;
                }
                .rightbtm{
                    height:180px;
                    // background: url(~@/assets/images/link_map.png) no-repeat left top;
                    background-size: 100% 100% !important;
                }
            }
            .pc-product-content{
                padding: 40px;
            }
        }
        
    }
}
@media screen and(min-width: 768px) and (max-width: 959px) {
    .wrap{
        .wap-ban-box{display: none};
        .wap-product-wrap{display: none;}
        .wap-product-showinfo{display: none;}
        .wap-waterfall-wrap{display: none;}
        
        .pc-product-showinfo{
            .pc-product-info{
                padding:20px 10px;
                .productBox{
                    padding: 30px 0;
                    .lias{
                        text-decoration: none;
                        .productBox-tit{
                            font-size:20px;
                        }
                        
                    }
                }
                .productRigth{
                    padding: 10px 0;
                    margin:10px 10px 20px;
                    .lias{
                        text-decoration: none;
                        .productBox-title{
                            text-align: left;
                            font-weight: bold;
                            font-size:20px;
                            padding-top:20px;
                            padding-left:20px;
                            padding-right:20px;
                            color: #2e2e2e;
                        }
                        .productBox-morele{
                            color: #1f73b7;
                            font-weight: bold;
                            text-align: left;
                            
                            padding-top:20px;
                            padding-left:20px;
                        }
                    }
                }
                .leftbig{
                    height:380px;
                    // background: url(~@/assets/images/show_cp.png) no-repeat left top;
                    background-size: 100% 100% !important;
                }
                .righttop{
                    height:180px;
                    // background: url(~@/assets/images/link_moreinfo.png) no-repeat left top;
                    background-size: 100% 100% !important;
                }
                .rightbtm{
                    height:180px;
                    // background: url(~@/assets/images/link_map.png) no-repeat left top;
                    background-size: 100% 100% !important;
                }
            }
            .pc-product-content{
                padding: 40px;
            }
        }
    }
}
// @media screen and(min-width: 480px) and (max-width: 767px) {
//     .wrap{
//         .pc-ban-box{display: none};
//         .wap-product-wrap{display: none;}
//         .wap-product-showinfo{display: none;}
//         // .wap-waterfall-wrap{display: none;}
//         .wap-waterfall-wrap{
//             .cell-item{
//                 .item-body {
//                     margin:10px 5px;
//                     padding:10px 0;
//                     background: #f8f9fa;
//                     .item-desc {
//                         text-align: left;
//                         font-family: Roboto;
//                         font-style: normal;
//                         font-weight: normal;
                        
//                         line-height: 26px;
//                         padding:10px;
//                         color: #2e2e2e;
//                     }
//                     .item-start{
//                         display: flex;
//                         padding:4px 10px 0;
                       
//                         img{
//                             width:20px;
//                             margin-right: 2px;
//                         }
//                     }
//                     .item-footer {
//                         display: flex;
//                         justify-content: space-between;
//                         padding:0 10px;
//                         .footer-left {
//                             display: flex;
//                             align-items: center;
//                             font-family: SF Pro Display;
//                             font-style: normal;
//                             font-weight: normal;
                            
//                             .name {
//                                 font-size:16px;
//                             }
                            
//                         }
//                         .like {
//                             display: flex;
//                             align-items: center;
//                             justify-content: center;
//                             font-family: SF Pro Display;
//                             font-style: normal;
//                             font-weight: normal;
                            
//                             line-height: 14px;
//                             color: rgba(0, 0, 0, 0.4);
//                         }
//                     }
//                 }
//                 .cell-item {
//                     width: 96%;
//                     margin-bottom: 6px;
//                     background: #f8f9fa;
//                     border: 2px solid #f0f0f0;
//                     border-radius: 6px;
//                     margin:0 2%;
//                     overflow: hidden;
//                     box-sizing: border-box;
//                 }
//             }
//         }
//         
//         .pc-product-showinfo{
//             .pc-product-info{
//                 padding:20px 10px;
//                 .productBox{
//                     padding: 30px 0;
//                     .lias{
//                         text-decoration: none;
//                         .productBox-tit{
//                             font-size:20px;
//                         }
//                         
//                     }
//                 }
//                 .productRigth{
//                     padding: 10px 0;
//                     margin:10px 10px 20px;
//                     .lias{
//                         text-decoration: none;
//                         .productBox-title{
//                             text-align: left;
//                             font-weight: bold;
//                             font-size:20px;
//                             padding-top:20px;
//                             padding-left:20px;
//                             padding-right:20px;
//                             color: #2e2e2e;
//                         }
//                         .productBox-morele{
//                             color: #1f73b7;
//                             font-weight: bold;
//                             text-align: left;
                            
//                             padding-top:20px;
//                             padding-left:20px;
//                         }
//                     }
//                 }
//                 .leftbig{
//                     height:380px;
//                     // background: url(~@/assets/images/show_cp.png) no-repeat left top;
//                     background-size: 100% 100% !important;
//                 }
//                 .righttop{
//                     height:180px;
//                     // background: url(~@/assets/images/link_moreinfo.png) no-repeat left top;
//                     background-size: 100% 100% !important;
//                 }
//                 .rightbtm{
//                     height:180px;
//                     // background: url(~@/assets/images/link_map.png) no-repeat left top;
//                     background-size: 100% 100% !important;
//                 }
//             }
//             .pc-product-content{
//                 padding: 40px;
//             }
//         }
//         .pc-waterfall-wrap{
//             display: none;
//         }
//     }
// }
@media screen and(min-width: 480px) and (max-width: 767px) {
    .wrap{
        background: #f5f5f5;
        .pc-ban-box{display: none};
        .modal-wrap{
            .modal-inner{
                width:96%;
            }
        }
        .pc-product-wrap{
            display: none;
        }
        .pc-show{
            display: none;
        }
        .wap-product-wrap{
            background: #f5f5f5;
            padding:30px 0 0px;
            .wap-commodity-group{
                padding:0 5px;
                .wap-commodity-item{
                    background: #fff;
                    border-radius:10px;
                    padding:10px 0px;
                    margin:0 10px 20px;
                    .wap-commodity-item-img{
                        cursor: pointer;
                        .left-btn-img{
                            width: 100%;
                            // height:140px;
                        }
                    }
                    .wap-commodity-item-tit{
                        padding:0 10px;
                        line-height: 30px;
                        height:30px;
                        
                        overflow:hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        -o-text-overflow:ellipsis;

                    }
                    .wap-commodity-item-info{
                        color: #9d9da5;
                        height:30px;
                        padding:0px 10px 0px;
                        
                        overflow:hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        -o-text-overflow:ellipsis;
                    }
                    .wap-commodity-item-money{
                        padding:0 10px;
                    }
                }
            }
        }
        .mg{
            height:20px;
            background: #f5f5f5;
        }
        .wap-waterfall-wrap{
            .cell-item{
                .item-body {
                    margin:10px 5px;
                    padding:10px 0;
                    background: #f8f9fa;
                    .item-desc {
                        text-align: left;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        
                        line-height: 26px;
                        padding:10px;
                        color: #2e2e2e;
                    }
                    .item-start{
                        display: flex;
                        padding:4px 10px 0;
                        
                        img{
                            width:20px;
                            margin-right: 2px;
                        }
                    }
                    .item-footer {
                        display: flex;
                        justify-content: space-between;
                        padding:0 10px;
                        .footer-left {
                            display: flex;
                            align-items: center;
                            font-family: SF Pro Display;
                            font-style: normal;
                            font-weight: normal;
                            
                            .name {
                                font-size:16px;
                            }
                            
                        }
                        .like {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-family: SF Pro Display;
                            font-style: normal;
                            font-weight: normal;
                            
                            line-height: 14px;
                            color: rgba(0, 0, 0, 0.4);
                        }
                    }
                }
                .cell-item {
                    width: 96%;
                    margin-bottom: 6px;
                    background: #f8f9fa;
                    border: 2px solid #f0f0f0;
                    border-radius: 6px;
                    margin:0 2%;
                    overflow: hidden;
                    box-sizing: border-box;
                }
            }
        }
        .wap-product-showinfo{
            padding:0;
            background: #f5f5f5;
            margin-bottom: 30px;
            .wap-product-info{
                padding:0 20px;
                border-radius: 15px;
                .productBox{
                    padding: 10px 10px 0;
                    img{
                        width: 100%;
                    }
                }
                .wap-productBox{
                    height:480px;
                    width: 100%;
                    padding:30px 0;
                    background-size: 100% 100% !important;
                    .lias{
                        text-decoration: none;
                        cursor: pointer;
                        .wap-productBox-tit{
                            color: #fff;
                            text-align: center;
                            font-size:24px;
                            padding:10px 0 50px;
                            cursor: pointer;
                        }
                        .wap-productBox-txt{
                            color: #fff;
                            text-align: center;
                            
                            padding:10px 0 50px;
                        }
                        .wap-productBox-more{
                            color: #1f73b7;
                            text-align: center;
                            
                            cursor: pointer;
                        }
                    }
                }
                .wap-productBox2{
                    height:180px;
                    width: 100%;
                    padding:40px 180px 0 30px;
                    margin-top: 20px;
                    // position: relative;
                    cursor: pointer;
                    border-radius: 15px;
                    background-size: 100% 100% !important;
                    // .rig_img{
                    //     position: absolute;
                    //     left: 0;
                    //     top:0;
                    //     z-index:0;
                    //     height:100%;
                    //     width: 100%;
                    //     img{
                    //         width:100%;
                    //         height:100%;
                    //         border-radius:20px;
                    //     }
                    // }
                    .lias{
                        // display: block;
                        // position: absolute;
                        // left: 0;
                        // top:0;
                        // z-index:1;
                        // height:100%;
                        text-decoration: none;
                        .wap-productBox-title{
                            font-size:20px;
                            padding:10px 0px 20px;
                            color: #2e2e2e;
                            cursor: pointer;
                        }
                        .wap-productBox-morele{
                            color: #1f73b7;
                            
                            padding:10px 0px 20px;
                            cursor: pointer;
                        }
                    }
                }
                .rowtop{
                    background: url(~@/assets/images/link_moreinfo.png) no-repeat left top;
                    background-size: 100% 100%;
                }
                .rowbtm{
                    background: url(~@/assets/images/link_map.png) no-repeat left top;
                    background-size: 100% 100%;
                }
            }
        }
        .pc-waterfall-wrap{
            display: none;
        }
    }
}
@media screen and (max-width: 479px) {
    .wrap{
        background: #f5f5f5;
        .pc-ban-box{display: none};
        .modal-wrap{
            .modal-inner{
                width:96%;
            }
        }
        .pc-product-wrap{
            display: none;
        }
        .pc-show{
            display: none;
        }
        .wap-product-wrap{
            background: #f5f5f5;
            padding:30px 0 0px;
            .wap-commodity-group{
                padding:0 5px;
                .wap-commodity-item{
                    background: #fff;
                    border-radius:10px;
                    padding:10px 0px;
                    margin:0 10px 20px;
                    .wap-commodity-item-img{
                        cursor: pointer;
                        .left-btn-img{
                            width: 100%;
                            // height:140px;
                        }
                    }
                    .wap-commodity-item-tit{
                        padding:0 10px;
                        line-height: 30px;
                        height:30px;
                        
                        overflow:hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        -o-text-overflow:ellipsis;

                    }
                    .wap-commodity-item-info{
                        color: #9d9da5;
                        height:30px;
                        padding:0px 10px 0px;
                        
                        overflow:hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                        -o-text-overflow:ellipsis;
                    }
                    .wap-commodity-item-money{
                        padding:0 10px;
                    }
                }
            }
        }
        .mg{
            height:20px;
            background: #f5f5f5;
        }
        .wap-waterfall-wrap{
            .cell-item{
                .item-body {
                    margin:10px 5px;
                    padding:10px 0;
                    background: #f8f9fa;
                    .item-desc {
                        text-align: left;
                        font-family: Roboto;
                        font-style: normal;
                        font-weight: normal;
                        
                        line-height: 26px;
                        padding:10px;
                        color: #2e2e2e;
                    }
                    .item-start{
                        display: flex;
                        padding:4px 10px 0;
                        
                        img{
                            width:20px;
                            margin-right: 2px;
                        }
                    }
                    .item-footer {
                        display: flex;
                        justify-content: space-between;
                        padding:0 10px;
                        .footer-left {
                            display: flex;
                            align-items: center;
                            font-family: SF Pro Display;
                            font-style: normal;
                            font-weight: normal;
                            
                            .name {
                                font-size:16px;
                            }
                            
                        }
                        .like {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-family: SF Pro Display;
                            font-style: normal;
                            font-weight: normal;
                            
                            line-height: 14px;
                            color: rgba(0, 0, 0, 0.4);
                        }
                    }
                }
                .cell-item {
                    width: 96%;
                    margin-bottom: 6px;
                    background: #f8f9fa;
                    border: 2px solid #f0f0f0;
                    border-radius: 6px;
                    margin:0 2%;
                    overflow: hidden;
                    box-sizing: border-box;
                }
            }
        }
        .wap-product-showinfo{
            padding:0;
            background: #f5f5f5;
            margin-bottom: 30px;
            .wap-product-info{
                padding:0 20px;
                .productBox{
                    padding: 10px 10px 0;
                    img{
                        width: 100%;
                    }
                }
                .wap-productBox{
                    height:480px;
                    width: 100%;
                    padding:30px 0;
                    background-size: 100% 100% !important;
                    .lias{
                        text-decoration: none;
                        cursor: pointer;
                        .wap-productBox-tit{
                            color: #fff;
                            text-align: center;
                            font-size:24px;
                            padding:10px 0 50px;
                            cursor: pointer;
                        }
                        .wap-productBox-txt{
                            color: #fff;
                            text-align: center;
                            
                            padding:10px 0 50px;
                        }
                        .wap-productBox-more{
                            color: #1f73b7;
                            text-align: center;
                            
                            cursor: pointer;
                        }
                    }
                }
                .wap-productBox2{
                    height:180px;
                    width: 100%;
                    padding:40px 180px 0 30px;
                    margin-top: 20px;
                    // position: relative;
                    cursor: pointer;
                    border-radius: 15px;
                    background-size: 100% 100% !important;
                    // .rig_img{
                    //     position: absolute;
                    //     left: 0;
                    //     top:0;
                    //     z-index:0;
                    //     height:100%;
                    //     width: 100%;
                    //     img{
                    //         width:100%;
                    //         height:100%;
                    //         border-radius:20px;
                    //     }
                    // }
                    .lias{
                        // display: block;
                        // position: absolute;
                        // left: 0;
                        // top:0;
                        // z-index:1;
                        // height:100%;
                        text-decoration: none;
                        .wap-productBox-title{
                            font-size:20px;
                            padding:10px 0px 20px;
                            color: #2e2e2e;
                            cursor: pointer;
                        }
                        .wap-productBox-morele{
                            color: #1f73b7;
                            
                            padding:10px 0px 20px;
                            cursor: pointer;
                        }
                    }
                }
                .rowtop{
                    background: url(~@/assets/images/link_moreinfo.png) no-repeat left top;
                    background-size: 100% 100%;
                }
                .rowbtm{
                    background: url(~@/assets/images/link_map.png) no-repeat left top;
                    background-size: 100% 100%;
                }
            }
        }
        .pc-waterfall-wrap{
            display: none;
        }
    }
}
</style>